export default {
  primaryRed: '#d01408',
  white: '#FFF',
  grey800: '#1f232c',
  grey100: '#f4f5f9',
  grey200: '#9eaacf',
  lightBlack: '#282C36',
  prestationBlue: '#2C50A0',
  prestationGreen: '#2CA070',
  prestationPurple: '#6122AB',
  prestationGrey: '#8D9BBA',
};
