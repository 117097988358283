import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { ButtonComponent } from '@zolteam/axenergie-ui-library';
import Image from 'next/image';
import Link from 'next/link';

// Utils
import strings from '../../constants/Strings';

// Images
import userIcon from '../../public/icons/user.svg';
import burgerIcon from '../../public/icons/burger.svg';
import logoWhite from '../../public/Images/logo-white.webp';
import logoColor from '../../public/Images/logo.webp';
import closeIcon from '../../public/icons/close.svg';
import useIsMobile from '../../hooks/useIsMobile';

const Menu = ({
  items, mobileTheme, textColor,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [isMobile] = useIsMobile();

  const inlineMenu = (style) => (
    <div
      className={`m-2 d-flex align-center f-wrap ${style} 
      ${mobileTheme === 'dark' && isMobile ? 'grey-100-background' : ''}`}
    >
      {items?.items?.map(({ label, link }) => (
        <Link href={link} key={`${label}-${link}`}>
          <a className={`medium-weight m-4 ${!isMobile ? textColor : ''} regular-text`}>{label}</a>
        </Link>
      ))}
      {items?.showExtranet ? (
        <div className="m-2">
          <Link href={process.env.NEXT_PUBLIC_EXTRANET_URL}>
            <a style={{ opacity: 1 }}>
              <ButtonComponent
                onClick={() => {
                }}
                theme="black"
              >
                <div className="d-flex justify-center align-center">
                  <span>{strings.userSpace}</span>
                  <div className="mh-2">
                    <Image alt="user" src={userIcon} width={20} />
                  </div>
                </div>
              </ButtonComponent>
            </a>
          </Link>
        </div>
      ) : null}
    </div>
  );

  return (
    isMobile
      ? (
        <div className="relative full-screen-width">
          <div className="d-flex full-width justify-between align-center f-row ph-4">
            <Link href="/">
              <a>
                <Image
                  alt="logo"
                  src={mobileTheme === 'light' ? logoWhite : logoColor}
                  width={125}
                  height={125}
                />
              </a>
            </Link>
            <div className={mobileTheme === 'dark' ? 'radius-5 grey-800-background p-2' : ''}>
              <button type="button" onClick={() => setOpenMenu(!openMenu)}>
                <Image alt="menu" src={openMenu ? closeIcon : burgerIcon} width={30} />
              </button>
            </div>
          </div>
          <div className="
          full-width d-flex justify-end align-start f-row white-background absolute absolute-right-top-corner
          "
          >
            {
                openMenu
                  ? inlineMenu('f-row  align-start pt-2', 'black-text') : null
            }
            {openMenu ? (
              <button className="pt-2" type="button" onClick={() => setOpenMenu(!openMenu)}>
                <div className="d-flex align-center justify-center menu-close-button mt-4 mr-4">
                  <Image alt="menu" src={openMenu ? closeIcon : burgerIcon} width={30} color="#000" />
                </div>
              </button>
            ) : null}
          </div>
        </div>
      )
      : inlineMenu()
  );
};

Menu.propTypes = {
  items: PropTypes.shape(
    {
      items: PropTypes.arrayOf(PropTypes.shape({ link: PropTypes.string, label: PropTypes.string })),
      showExtranet: PropTypes.bool,
    },
  ).isRequired,
  mobileTheme: PropTypes.oneOf(['light', 'dark']),
  textColor: PropTypes.string,
};

Menu.defaultProps = {
  mobileTheme: 'light',
  textColor: 'white-text',
};

export default Menu;
