const formatCMSDatas = (domain, props) => {
  const items = Object.entries(props).reduce((acc, [key, label], index, array) => {
    if (key.startsWith(`${domain}Libell`)) {
      const entryIndex = key.split(`${domain}Libell`).pop();
      const link = array.find(([entryKey]) => entryKey === `${domain}Lien${entryIndex}`);
      return [...acc, {
        link: link ? link[1] : '',
        label,
      }];
    }
    return acc;
  }, []);
  if (domain === 'menu' || domain === 'footerClient') {
    return {
      items,
      showExtranet: typeof props?.afficherLeBoutonEspaceClient === 'boolean'
        ? props?.afficherLeBoutonEspaceClient : true,
    };
  }
  return items;
};

const formatCMSEnergies = (props) => Object.entries(props).reduce((acc, [key, label], index, array) => {
  if (key.startsWith('energie') && key.length === 8) {
    const entryIndex = key.split('energie').pop();
    const details = array.find(([entryKey]) => entryKey === `energie${entryIndex}Dtails`);
    const picto = array.find(([entryKey]) => entryKey === `energie${entryIndex}Picto`);
    const link = array.find(([entryKey]) => entryKey === `energie${entryIndex}Lien`);

    return [...acc, {
      picto: picto ? picto[1] : '',
      details: details ? details[1] : '',
      link: link ? link[1] : '',
      label,
    }];
  }
  return acc;
}, []);

const formatCMSLogoExpert = (props) => Object.entries(props).reduce((acc, [key, value]) => {
  if (key.startsWith('logo') && key.includes('BlocExpert')) {
    return [...acc, { url: value.fields.file.url }];
  }
  return acc;
}, []);

const formatCMSReassurance = (props) => Object.entries(props).reduce((acc, [key, label], index, array) => {
  if (key.startsWith('rassurance') && key.length === 11) {
    const entryIndex = key.split('rassurance').pop();
    const picto = array.find(([entryKey]) => entryKey === `rassurance${entryIndex}Picto`);
    return [...acc, {
      picto: picto ? picto[1] : '',
      label,
    }];
  }
  return acc;
}, []);

const formatCMSPartners = (props) => Object.entries(props.blocPartenaire.fields).reduce((acc,
  [key, value], index, array) => {
  if (key.startsWith('partenaire')) {
    const entryIndex = key.split('partenaire').pop();
    const link = array.find(([entryKey]) => entryKey === `lienPartenaire${entryIndex}`);
    return [...acc, { label: value.fields.title, imageUrl: value.fields.file.url, link: link ? link[1] : '' }];
  }
  return acc;
}, []);

const formatCMSJobs = (props) => Object.entries(props).reduce((acc, [key, label], index, array) => {
  if (key.startsWith('mtier') && key.length === 6) {
    const entryIndex = key.split('mtier').pop();
    const picto = array.find(([entryKey]) => entryKey === `mtier${entryIndex}Picto`);
    return [...acc, {
      picto: picto ? picto[1] : '',
      label,
    }];
  }
  return acc;
}, []);

export default {
  formatCMSDatas,
  formatCMSLogoExpert,
  formatCMSEnergies,
  formatCMSReassurance,
  formatCMSPartners,
  formatCMSJobs,
};
